import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Global } from "./Global";
import {
    AppBase,
    ScrollToTop,
    TrackedRoute,
    TranslatePage,
    useEvents,
    UserNavigationEvent,
} from "@ingka-livlig/frontend-lib";
import { QueryParamProvider } from "use-query-params";
import { localeActivate } from "./i18n";
import { withStore } from "react-context-hook";
import NotFound from "./NotFound";
import { useAPI } from "./api";
import React from "react";
import { MetricsCountry } from "./MetricsCountry";
import { MetricsSellingUnit } from "./MetricsSellingUnit";
import { MetricsRole } from "./MetricsRole";
import { MetricsMostViewedPage } from "./MetricsMostViewedPage";
import { MetricsHfbPage } from "./MetricsHfbPage";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { useLingui } from "@lingui/react";
import * as englishMessages from "./locales/en/messages.mjs";

const Translate = () => {
    const api = useAPI();
    return (
        <TranslatePage
            recordTranslation={api.recordTranslation}
            fetchEnglishTransations={() => englishMessages.messages}
        />
    );
};

function AppRoutes() {
    const events = useEvents<UserNavigationEvent>(useAPI());
    /**
     * This is put here in order to force Lingui to remount when language changes.
     * With Lingui 4 the default is to only rerender when language us changed. This works nicely when <Trans> elements
     * are used, but doesn't work when the t`...` templated string is used. The result is a lot of untranslated strings.
     * We handle this by remounting everything, which shouldn't be an issue as it's expected that users seldomly
     * change languages.
     *
     * In addition, we rely on the selected locale to fetch proper names of HFBs and PAs from the server, so we need to
     * trigger a refetch anyway.
     */
    useLingui();

    return (
        <Routes>
            <Route
                path="/translate"
                element={
                    <TrackedRoute routeName="Translate" events={events}>
                        <Translate />
                    </TrackedRoute>
                }
            />
            <Route
                path="/"
                element={
                    <TrackedRoute routeName="Root" events={events}>
                        <Global />
                    </TrackedRoute>
                }
            />
            <Route
                path="/locations/:locationType/:locationId"
                element={
                    <TrackedRoute routeName="MetricsCountry" events={events}>
                        <MetricsCountry />
                    </TrackedRoute>
                }
            />
            <Route
                path="/locations/SellingUnit/:locationId"
                element={
                    <TrackedRoute routeName="MetricsSellingUnit" events={events}>
                        <MetricsSellingUnit />
                    </TrackedRoute>
                }
            />
            <Route
                path="/locations/:locationType"
                element={
                    <TrackedRoute routeName="Global" events={events}>
                        <Global />
                    </TrackedRoute>
                }
            />
            <Route
                path="/locations/:locationType/role"
                element={
                    <TrackedRoute routeName="MetricsRole" events={events}>
                        <MetricsRole />
                    </TrackedRoute>
                }
            />
            <Route
                path="/locations/:locationType/HFB"
                element={
                    <TrackedRoute routeName="MetricsHfbPage" events={events}>
                        <MetricsHfbPage />
                    </TrackedRoute>
                }
            />
            <Route
                path="/locations/:locationType/role"
                element={
                    <TrackedRoute routeName="MetricsRole" events={events}>
                        <MetricsRole />
                    </TrackedRoute>
                }
            />
            <Route
                path="/locations/:locationType/:locationId/role"
                element={
                    <TrackedRoute routeName="MetricsRole" events={events}>
                        <MetricsRole />
                    </TrackedRoute>
                }
            />
            <Route
                path="/locations/:locationType/feature"
                element={
                    <TrackedRoute routeName="MetricsMostViewedPage" events={events}>
                        <MetricsMostViewedPage />
                    </TrackedRoute>
                }
            />
            <Route
                path="/locations/:locationType/:locationId/feature"
                element={
                    <TrackedRoute routeName="MetricsMostViewedPage" events={events}>
                        <MetricsMostViewedPage />
                    </TrackedRoute>
                }
            />
            <Route
                path="/locations/:locationType/:locationId/HFB"
                element={
                    <TrackedRoute routeName="MetricsHfbPage" events={events}>
                        <MetricsHfbPage />
                    </TrackedRoute>
                }
            />
            <Route
                path="*"
                element={
                    <TrackedRoute routeName="NotFound" events={events}>
                        <NotFound />
                    </TrackedRoute>
                }
            />
        </Routes>
    );
}

function MainRouter() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <AppBase localeActivate={localeActivate}>
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                    <AppRoutes />
                </QueryParamProvider>
            </AppBase>
        </BrowserRouter>
    );
}

export default withStore(MainRouter, {});
